import React from "react"
import Title from "../Title"
import styles from "../../css/about.module.css"
import img from "../../images/map.jpg"
// import { useStaticQuery, graphql } from 'gatsby'
// import Img from 'gatsby-image'

// const getAbout = graphql`
// query aboutImage{
//   aboutImage: file(relativePath: {eq: "map.jpg"}) {
//     childImageSharp {
//       fluid(maxWidth: 600) {
//         ...GatsbyImageSharpFluid_tracedSVG
//       }
//     }
//   }
// }
// `;

const About = () => {
  // const { aboutImage } = useStaticQuery(getAbout);
  return (
    <section className={styles.about}>
      <Title title="about" subtitle="EscambiaSoft" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
             <img src={img} alt="about company" />
            {/*<Img fluid={aboutImage.childImageSharp.fluid} alt="landscape" />*/}
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>Salesforce & AWS Partner</h4>
          <p>
    EscambiaSoft was established with the intention of being a partner with business in their need for immediate software solutions. We are Salesforce Consulting Partner with certified consultants having years of experience in customizing and integrating Salesforce solutions and establishing cloud-based infrastructure for our clients.
          </p>
          <p>
    Data Analytics to Cloud computing we have done it all. With a solid dedicated partnership with Salesforce and AWS, we have established ourselves as leaders in the industry with a solid footing in skills and execution of projects.
          </p>
            <p className={styles.aboutCt}>
                A quick note through "contact us" will assign a solution architect immediately to guide you on the project. It's a free consultation and is faster than scheduling time by calling us.
            </p>
          <a href="/contact" className="btn-primary" target="_blank" rel="noopener noreferrer">
            contact us
          </a>
        </article>
      </div>
    </section>
  )
}

export default About
