import React, { Component } from 'react'
import Slider from "react-slick";
import styles from "../../css/carousel.module.css"
import Carousel from './Carousel'
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import { useStaticQuery, graphql } from "gatsby"

const getComments = graphql`
query{
comments: allContentfulTestimonials {
 edges {
      node {
        slug
        contentful_id
         commenter
        title
        comment {
          comment
        }
      }
    }
    }
}
`;
    const CarouselList = () => {
        const { comments } = useStaticQuery(getComments)

        const settings = {
            dots: true,
            infinite: true,
            speed: 5,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (

            <div>
                <div className={styles.title}>
                    <p><center><h3> <b>Testimonials </b></h3></center></p>
                </div>
                <Slider {...settings}>
                    {comments.edges.map(({ node }) => {
                       return  <Carousel commentNode={node}/>
                    })}
                </Slider>
            </div>
        )
}
export default  CarouselList