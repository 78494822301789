import React, { Component } from 'react'
import styles from "../../css/carousel.module.css"


const Carousel = ({ commentNode }) => {
        return (

                      <div>
                        <center>
                            <article>
                                <span> <p className={styles.p1}> {commentNode.comment.comment}</p> </span>
                                <div className={styles.p1}> {commentNode.commenter}  &nbsp;&nbsp; {commentNode.title}  </div>
                            </article>
                        </center>
                    </div>
        )
}
export default  Carousel