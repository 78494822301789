import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Title from "../Title"
import styles from "../../css/items.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Place from "../Places/Place"

const getFeaturedPlaces = graphql`
query{
featuredPlaces: allContentfulGreatProjects(filter:{featured:{eq:true}}) {
    edges {
        node {
             contentful_id
            name
            slug
            featured
        images {
            fluid {
                ...GatsbyContentfulFluid
                }
            }
        }
        }
    }
}
`;

const FeaturedPlaces = () => {
    const response = useStaticQuery(getFeaturedPlaces)
    const places = response.featuredPlaces.edges

    return (
        <section className={styles.places}>
            <Title title="featured" subtitle="projects" />
            <div className={styles.center}>
                {places.map(({ node }) => {
                    return <Place key={node.contentful_id} place={node} />
                })}
            </div>
            <AniLink fade to="/projects" className="btn-primary">
                Other Sample Projects
            </AniLink>
        </section>
    )
}

export default FeaturedPlaces
