import React from "react"
import { FaWallet, FaCamera, FaSocks } from "react-icons/fa"

export default [
  {
    icon: "Salesforce_Registered.png",
    title: "Salesforce Consulting Partner",
    text: "As Salesfroce consulting partners we have taken on various client problems and created solutions that supports their business.",
  },
  {
    icon: "aws_partner.png",
    title: "AWS Partner",
    text: "As AWS partners we have connected various AWS products to bring about effecient and secure solution for our clients.",
  },
  {
    icon: "salesforce_isv.png",
    title: "Salesforce ISV Partner",
    text: "Yes, we have created several managed packages to maintain and easily distribute our client solutions",
  },
]
